<template>

                    <form @submit.prevent="save();">



                        <div class="form-group">
                            <label for="name">SKU/Clave</label>
                            <input type="text" v-model="model.code" required id="name" class="form-control form-control-lg" placeholder="Escribe el nombre del modelo">
                        </div>                        

                        <div class="form-group" v-if="!$props.development_id">
                            <label for="name">Asignar a desarrollo</label>
                            <select class="form-control form-contol-lg" @change="onDevelopmentChange" ref="developmentControl" v-bind:required="!$props.development_id" v-model="model.development_id">
                                <option selected value="">Selecciona</option>
                            <option v-for="(option, index) in developments" :key="index" :value="option.id">{{ option.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="name">Elige el modelo</label>
                            <select class="form-control form-contol-lg" v-bind:required="!$props.design_id" v-model="model.design_id">
                                <option selected value="">Selecciona</option>
                                <option v-for="(option,index) in designs" :value="option.id" :key="index" v-text="option.name"></option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="name">Elige el piso/planta en la cual se ubica la unidad</label>
                            <select class="form-control form-contol-lg" v-bind:required="!$props.design_id" v-model="model.floor">
                                
                                <option v-for="index in floors" :value="index" :key="index" v-text="index"></option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="name">Elige el estatus de la unidad</label>
                            <select class="form-control form-contol-lg" v-model="model.status">
                                <option selected value="available">Disponible</option>
                                <option selected value="deposit">Apartado</option>
                                <option selected value="sold">Vendido</option>
                            </select>
                        </div>


                        
                        <div class="form-group">

                        <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>

                        </div>


                    </form>



            
</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';

export default {
    routeName:'properties',
    name: 'formProperty',
    components:{
        submitbutton
    },
    props:{

        id:{
            type:Number,
            required:false,
        },
        development_id:{
            type:Number,
            required:false,

        },
        design_id:{
            type:Number,
            required:false,

        },
        default_designs:{
            type:Array,
            required:false

        },
        default_developments:{
            type:Array,
            required:false

        }

    },  
    data(){

        return {

            floors:1,
            model:{
                id:'',
                code:'',
                development_id:'',
                design_id:'',
                floor:'',
                status:'',
           },
           developments:[],
           designs:[],
           
            pic:'',
            loading:false,

        }

    },

    methods:{


        onDevelopmentChange(event){
            const index=event.target.selectedIndex;
            this.fillDevChildrens(index-1);
            

        },


        fillDevChildrens(index){
            this.fillDesigns(index);
            this.floors=parseInt(this.developments[index].dev_floors);

        },


        
        fillDesigns(index){
            if(this.developments[index]!=undefined){
                this.designs=this.developments[index].designs;
            }else{
                this.designs=[];
            }
        },

        searchDevelopmentIndex(id){

            const index = this.developments.findIndex(function (item) {
                return item.id === id;
            });

            return index;

        },

        selectFile(event) {
         this.model.image = event.target.files[0];
        },

        save(){


            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        },


        async loadDevelopments(){



            

            if(this.developments.length==0){

                await api.get('/developments?expand=designs').then(response=>{

                    this.developments=response.data;



                });
            }


        },
        async loadItem(id){

            
            if(!this.isNewRecord){
            
                    api.get(this.me(id))
                    .then(response=>{
                        
                            const data=response.data;

                            
                            for (const key in data) {
                                if (this.model.hasOwnProperty(key)) {
                                    this.model[key] = data[key];
                                }
                            }
                            
                            
                            this.fillDevChildrens(this.searchDevelopmentIndex(data.development_id));
                            


                    }).catch(error=>{
                        console.log(error);
                    });

            }
        },

        async loadStart(id){

            await this.loadDevelopments();
            this.loadItem(id);
        }



    },


    
    
    mounted(){

    


        if(this.default_developments){
            this.developments=this.default_developments
        }

        if(this.default_designs){
            this.designs=this.default_designs;
        }

        if(this.development_id){
            this.model.development_id=this.development_id;
            
            
            this.fillDevChildrens(this.searchDevelopmentIndex(this.development_id));
        }
        if(this.design_id){
            this.model.design_id=this.design_id;
        }


            
        this.loadStart(this.$props.id);








    }


}

</script>
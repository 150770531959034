<template>

                    <form @submit.prevent="save">


                        <div>
                            <div>
                                <b-tabs  v-model="currentTab" ref="devTabs" content-class="mt-3">
                                    <b-tab>
                                        <template slot="title">
                                            <i class="mdi mdi-office-building-cog"></i>
                                            General
                                        </template>



                                            <div class="row">

                                                    <div class="col-md-6 col-sm-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label for="name">Nombre</label>
                                                            <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Nombre del desarrollo">
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="description">Descripción</label>


                                                            <textarea  v-model="model.description" required id="name" class="form-control form-control-lg" placeholder="Escribe una breve descripción" rows="5"></textarea>
                                                        </div>


                                                        <div class="form-group">
                                                            <label for="name">Tipo de uso</label>

                                                            <select class="form-control" v-model="model.use_type" required>
                                                                <option value="" selected>Selecciona</option>
                                                                <option value="residencial">Residencial</option>
                                                                <option value="comercial">Comercial</option>
                                                                <option value="mixto">Mixto</option>
                                                                
                                                            </select>
                                                        </div>


                                                        <div class="form-group">
                                                            <label for="name">Tipo de desarrollo</label>

                                                            <select class="form-control" v-model="model.dev_type" required>
                                                                <option value="" selected>Selecciona</option>
                                                                <option value="departamentos">Departamentos</option>
                                                                <option value="condominio_horizontal">Condominio horizontal</option>
                                                            </select>                                                            
                                                        </div>
                                                        <!--
                                                        <div class="form-group" v-if="model.dev_type=='departamentos'">
                                                            <label for="name">Pisos/Plantas</label>
                                                            
                                                            <input type="text" v-model="model.dev_floors" class="form-control" placeholder="Escribe el número de pisos/plantas"/>

                                                        </div>-->


                                                        <div class="form-group">
                                                            <label for="name">Imagen de portada</label>

                                                            <img v-if="isNewRecord?false:true" class="thumb" v-bind:src="pic"/>
                                                            <input ref="featured_image" type="file" v-bind:required="isNewRecord" class="form-control form-control-lg" v-on:change="selectFile" id="pic"  placeholder="Imagen de portada" accept=".jpg,.jpeg,.png,.x-png,.gif">
                                                        </div>


                                                        <div class="form-group">
                                                            <label>Amenidades</label>    
                                                            <multiselect selectLabel="Presiona enter para agregar" :key="multi_counter" v-if="isReady" :value="selectedAmenities" @tag="newAmenity" @input="addAmenity" tag-placeholder="Agregar como etiqueta nueva" placeholder="Busca o agrega una etiqueta" label="name" track-by="id" :options="model.catalog_amenities" :hideSelected="true" :multiple="true" :taggable="true">
                                                                    <template slot="noOptions">Lista vacía</template>
                                                            
                                                            </multiselect>
                                                        </div>
                                                            
                                                        
                                                    </div>
                                                    <div class="col-md-6 col-sm-6 col-lg-6">


                                                        <div class="row">
                                                            <div class="col-md-6 col-lg-6 col-sm-6">
                                                                <div class="form-group">
                                                                    <label>Inicio de obra</label>
                                                                    <Datepicker :position="'bottom'" :inputClass="'form-control form-control-lg'" lang="es" v-model="model.start_date" placeholder="Selecciona la fecha"/>
                                                                </div>
                                                                
                                                            </div>
                                                            <div class="col-md-6 col-lg-6 col-sm-6">
                                                                <div class="form-group">
                                                                    <label>Fecha de entrega</label>
                                                                    <Datepicker :position="'bottom'" :inputClass="'form-control form-control-lg'" lang="es" v-model="model.end_date" placeholder="Selecciona la fecha"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="name">Ubicación</label>
                                                            <GmapAutocomplete v-bind:value="model.address" @place_changed="onPlacesChanged" class="form-control"></GmapAutocomplete>
                                                            <!--<input type="text" v-model="model.address" required id="direccion" ref="searchbox" autocomplete="off" class="form-control form-control-lg" placeholder="Escribe la dirección"/>-->
                                                        </div>
                                                        <gmap-map  @click="onMapClick" style="height: 500px;" :center="{lat:getLatitude,lng:getLongitude}" :zoom="getZoom" @zoom_changed="onZoomChanged"> 
                                                            <gmap-marker :draggable="true" :position="{lat:getLatitude,lng:getLongitude}" @dragend="onMapDragEnd"/>
                                                        </gmap-map>

                                                        <br/>

                                                        <div class="form-group">
                                                            <label>Brochure</label>
                                                            <br>
                                                            <a class="action-link" :title="filename(model.brochure)" v-if="model.brochure" target="_blank" :href="fil(model.brochure)"><i :class="fileico(model.brochure)+'  mdi-24px'"></i> {{filename(model.brochure)}}</a>
                                                            <input ref="brochure" type="file" class="form-control form-control-lg" v-on:change="selectBrochure" id="brochure"  placeholder="Brochure" accept=".jpg,.jpeg,.png,.x-png,.gif,.pdf,.doc,.docx">

                                                        </div>


                                                    </div>
                                            </div>



                                              
                                        
                                    </b-tab>



                                    <b-tab v-bind:disabled="created?false:true">

                                        <template slot="title">
                                            <i class="mdi mdi-math-compass"></i>
                                            Modelos
                                        </template>

                                        <div class="thumbs-container">

                                            <div class="" v-if="hasDesigns">
                                                <p>Este desarrollo tiene {{countDesigns}} modelo{{ countDesigns>1?'s':'' }}</p>
                                            </div>
                                            <p v-else>No has registrado modelos para este desarrollo <a @click="newDesign">crea uno</a></p>
                                            
                                                <div>
                                                
                                                    <div class="thumbs-items">
                                                        
                                                        <div v-for="(item,index) in model.designs" :key="index" class="object-thumb">
                                                            <thumbDesign :id="item.id" @edit="editDesign" :index="index" :name="item.name" @afterDelete="afterDesignDelete" :image="item.image"/>
                                                        </div>
                                                        
                                                        <a @click="newDesign" class="object-thumb thumb-plus"><span><i class="mdi mdi-note-plus-outline"></i></span></a>

                                                    </div>
                                                </div>
                                                

                                        
                                            </div>

                                        
                                        <b-modal ref="modalDesign" id="form-modal" size="md" title="Agregar un modelo a tu desarrollo" :hide-footer="true">
                                            <formDesign v-if="isReady" :currencies="currencies" :id="parseInt(design_id)" :development_id="parseInt(model.id)" @afterSave="afterDesignSave" ref="formDesign"/>
                                        </b-modal>


                                    </b-tab>


                                    <b-tab>
                                        <template slot="title">
                                            <i class="mdi mdi-home-group-plus"></i>
                                            Fases
                                        </template>

                                        <embedListPhases :dev_type="model.dev_type" v-if="isReady" :designs="model.designs" :data="model.phases" :development_id="model.id"/>


                                    </b-tab>




                                    <b-tab v-bind:disabled="created?false:true">
                                        <template slot="title">
                                            <i class="mdi mdi-home-percent-outline"></i>
                                            Inventario
                                        </template>


                                        
                                        <embedListProperties v-if="showListProperties" :key="countDesigns" :default_developments="makeDevelopments" :default_designs="model.designs" :data="model.properties" :default_development_id="parseInt(model.id)"/>
                                        
                                        <div class="wrapper" v-if="model.designs.length==0">

                                            <p>Debes crear un modelo primero </p>
                                            <a @click="newDesign" href="javascript:void(0)" class=""><i class="mdi mdi-note-plus-outline"></i> Crea uno</a>
                                        </div>

                                    </b-tab>




                                    <b-tab v-bind:disabled="created?false:true">

                                        <template slot="title">
                                            <i class="mdi mdi-calculator-variant-outline menu-icon"></i>
                                            Presupuesto
                                        </template>


                                        <div class="tab-container">
                                            <treeBudget v-if="showBudgets" :currencies="currencies" :items="getBudgets" :development_id="parseInt(model.id)"/>
                                        </div>
                                        


                                    </b-tab>

<!--

                                    <b-tab v-bind:disabled="created?false:true">
                                        <template slot="title">
                                            <i class="mdi mdi-home-plus-outline menu-icon"></i>
                                            Amenidades
                                        </template>

                                        <p>Marca las amenidades con las que cuenta el desarrollo</p>

                                        <div class="amenities-container">

                                                <div v-for="(item,index) in model.catalog_amenities" :key="index" class="item-amenity"> 
                                                    
                                                    <label><img v-bind:src="thumb(item.icon)"/><input type="checkbox" :value="item.id" v-model="model.developmentAmenities"> <span>{{ item.name }}</span></label>
                                                    
                                                </div>


                                        </div>


                                    </b-tab>

                                -->
                                    <b-tab v-bind:disabled="created?false:true">
                                        <template slot="title">
                                            <i class="mdi mdi-image-multiple"></i>
                                            Galería
                                        </template>

                                        <div class="upload-box" v-if="!uploading">
                                            <p>Selecciona las imagenes para subir</p>
                                            <input type="file" name="gallery"  v-on:change="uploadGallery" multiple accept=".jpg,.jpeg,.png,.x-png,.gif"/>
                                        </div>
                                        <div class="upload-widget" v-if="uploading">
                                            <progress-bar :text-position="'top'" :text="uploadingtext" :size="'large'" :val="uploadprogress" :max="100" :bar-color="'linear-gradient(to right, #d42f65, #a7357e)'"/>
                                        </div>

                                        <div class="thumbs-container">

                                            <div class="thumbs-items">

                                                <div v-for="(item,index) in model.developmentgalleries" :key="index" class="object-thumb">
                                                    <galleryThumb @afterDelete="afterGalleryDelete" :id="item.id" :index="index" :name="item.name" :image="item.image"/>
                                                </div>

                                            </div>

                                        </div>
                                    </b-tab>



                                    <b-tab>
                                        <template slot="title">
                                            <i class="mdi mdi-file-document-multiple-outline"></i>
                                            Documentos
                                        </template>

                                        <div class="table-responsive">
                                        
                                            <tableGrid :routeName="'documents'" :key="document_counter" :data="listDocuments" v-if="hasDocuments">

                                                    <template slot="headers">
                                                        <th>ID</th>
                                                        <th>Nombre</th>
                                                        <th>Acciones</th>
                                                    </template>

                                                    <template v-slot:row="item">
                                                        <td>{{ item.id }}</td>
                                                        <td>{{ item.name }}</td>
                                                    </template>
                                                    <template v-slot:edit="item">
                                                        <a class="action-link" href="javascript:void(0)" @click="updateDocument(item)"><i class="mdi mdi-square-edit-outline"></i></a>
                                                    </template>

                                            
                                            </tableGrid>
                                            <p class="tcenter">
                                                <a href="javascript:void(0)" @click="openDocumentDialog" class="action-link"><i class="mdi mdi-file-document-plus-outline"></i> Agregar documento</a>
                                            </p>
                                            <b-modal ref="modalDocument" title="Agregar un documento" :hide-footer="true" >
                                                <div class="card">

                                                    <div class="card-body">
                                                        <formDocument @afterSave="onDocumentSaved" :development_id="model.id" :id="document_id"/>
                                                    </div>
                                                </div>
                                            </b-modal>
                                        </div>

                                    </b-tab>


                                    <b-tab v-bind:disabled="created?false:true">
                                        <template slot="title">
                                            <i class="mdi mdi-home-percent-outline"></i>
                                            Proceso de venta
                                        </template>

                                        <embedListProcesses v-if="isReady" :data="model.processes" :development_id="model.id"/>

                                    </b-tab>


                                </b-tabs>
                            </div>
                        </div>




                        <div class="form-group float-right">

                            <submitbutton :label="!isNewRecord || created?'Actualizar':'Siguiente'" :loading_flag="loading"/>

                            
                        </div>

                        
                    </form>

</template>
<script>

import submitbutton from '@/components/ui-feature/submit-button';
import formDesign from '@/pages/designs/_form';
import thumbDesign from '@/pages/designs/thumb';
import galleryThumb from '@/pages/developmentgalleries/thumb';
import api from '@/util/api.js';
import ProgressBar from 'vue-simple-progress';
import embedListProperties from '@/pages/properties/embedList';
import treeBudget from '@/pages/budgets/treeview';
import embedListPhases from '@/pages/phases/embedList';
import embedListProcesses from '@/pages/processes/embedList';
import tableGrid from '@/components/tableGrid';
import formDocument from '@/pages/documents/_form';




//import treeExpenseconcepts from '@/pages/expenseconcepts/treeview';

export default{
    routeName:'developments',
    label:'Desarrollo',
    name: 'formDevelopment',

    components:{
      //  treeExpenseconcepts,

        treeBudget,
        submitbutton,
        formDesign,
        thumbDesign,
        ProgressBar,
        galleryThumb,
        embedListProperties,
        embedListPhases,
        embedListProcesses,
        tableGrid,
        formDocument
        
        
    
    },
    props:{
        id:{
            type:Number,
            required:false
        }
    },

    computed:{


        isReady(){

            return (this.isNewRecord || this.model.id!=0)?true:false;

        },

        listDocuments(){

            return this.model.documents;
        },


        hasDocuments(){

            return this.model.documents.length>0?true:false;

        },

        selectedAmenities(){

 
            return this.model.catalog_amenities.filter(obj => this.model.developmentAmenities.includes(obj.id));
            

        },

        makeDevelopments(){

            const devs=[];
            devs.push(this.model);
            return devs;

        },


        showListProperties(){

            
                
                
                    return this.model.id && this.model.designs.length>0;

                

        },


        showBudgets(){

            return this.show_budget;
        },


        getBudgets(){

            return this.budgets;

        },


        getDevelopmentId(){

            return parseInt(this.model.id);

        },

        textInterest(){

            let p='0';
            if(this.model.interest){
                p=this.model.interest;
            }
            return p+'%';
            
        },

        getLatitude(){
            return parseFloat(this.model.latitude);
        },


        getLongitude(){
            return parseFloat(this.model.longitude);
        },

        getZoom(){
            return parseInt(this.model.map_zoom);
        },


        hasDesigns(){

            

                return this.model.designs.length>0?true:false;

            

        },
        countDesigns(){


                return this.model.designs.length;


        },



    },
    data(){

        return {
            multi_counter:0,
            tagamenities:[],
            budgets:[],
            currencies:[],
            show_budget:false,
            document_counter:0,
            
            expensesinstance:0,
            categories:[],          
            searchbox:null,
            uploadingtext:'',
            uploading:false,
            uploadprogress:false,
            totalSteps:10,
            completedSteps:3,
            progress: 0,
            document_id:'',
            design_id:'',
            created:false,
            currentTab:0,
            model:{
                name:'',
                description:'',
                use_type:'',
                dev_type:'',
                dev_floors:1,
                address:'',
                latitude:20.966848,
                longitude:-89.611465,
                map_zoom: 12,
                id:'',
                featured_image:'',
                brochure:'',
                documents:[],
                designs:[],
                catalog_amenities:[],
                developmentAmenities:[],
                developmentgalleries:[],
                properties:[],
                down_payment:'10',
                down_payment_type:'percent',
                interest:'10',
                phases:[],
                processes:[],
                start_date:new Date(),
                end_date:new Date(),
                newAmenities:[]



            },
            loading:false,
            pic:'',



        }

    },

    methods:{


        fillDevelopmentAmenities(response){


            const r=response.data.developmentAmenities;

            const items=[];
            for(let i in r){

                items.push(r[i].amenity_id);

            }

            console.log(items);

            this.model.developmentAmenities=items;


        },

        addAmenity(i){

            
            
                this.model.developmentAmenities=[];

                
                for(let j in i){
                    const it=i[j];
                    this.model.developmentAmenities.push(it.id);
                }


        },

        newAmenity(e){

            
            
            const id='newAmenity'+this.multi_counter;

            
            this.model.newAmenities.push({name:e,id:id});
            this.model.catalog_amenities.push({name:e,id:id});
            this.model.developmentAmenities.push(id);


            
            this.multi_counter++;
            
        },

        onType(type){
            this.model.down_payment_type=type;
        },
        onValue(value){
            this.model.down_payment=value;
        },
        goTab(n){
            this.currentTab=n;
        },
        selectFile(event) {
         this.model.featured_image = event.target.files[0];
        },
        
        selectBrochure(event){
            
            this.model.brochure = event.target.files[0];

        },
        save(){


            this.model.start_date=this.$dateToString(this.model.start_date);
            this.model.end_date=this.$dateToString(this.model.end_date);

            const data=this.createFormData(this.model,['catalog_amenities','designs','developmentgalleries']);

            

            this.loading=true;

            
            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});

                    this.model.newAmenities=[];
                    this.isNewRecord=false;
                    this.fillModel(this.model,this.data);

                    this.fillDevelopmentAmenities(response);


                    this.model.catalog_amenities=response.data.amenities;
                    
                    this.$emit('afterSave',response);

                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                    this.loading=false;
                });
            
        }

        ,

        onMapClick(event) {
            

        const lat=event.latLng.lat(),lng=event.latLng.lng();
        this.latitude=lat;
        this.longitude=lng;


        
        const geocoder = new window.google.maps.Geocoder();
  
        const that=this;

            geocoder.geocode({ location: {lat:lat,lng:lng} }, (results, status) => {
                if (status === "OK") {
                if (results[0]) {
                    const placeName = results[0].formatted_address;
                    that.address=placeName;
                } else {
                    console.log("No se encontraron resultados");
                }
                } else {
                console.log(`Error de geocodificación: ${status}`);
                }
            });



        },

        onMapDragEnd(event){


            this.model.latitude=event.latLng.lat();
            this.model.longitude=event.latLng.lng();


        },

        onZoomChanged(zoom){

            this.model.map_zoom=zoom;
            
        },

        onPlacesChanged(event) {

            this.model.address=event.formatted_address;


            console.log(event.geometry);
            const  geometry  = event.geometry;


            this.model.latitude = geometry.location.lat();
            this.model.longitude=geometry.location.lng();        
            this.model.map_zoom = 14;

        },

        afterDesignSave(response){

            this.$refs.modalDesign.hide();
            
            const model=response.data;
            const index = this.model.designs.findIndex(item => item.id === model.id);

            if(index!=-1){
                this.fillModel(this.model.designs[index],model);
            }else{
                this.model.designs.push(model);
            }

            


        },

        afterDesignDelete(index){
            this.model.designs.splice(index,1);
        },

        editDesign(id){
            
            this.design_id=id;
            this.$refs.modalDesign.show();

        },

        newDesign(){
            
            this.design_id=null;
            this.$refs.modalDesign.show();

        },



        uploadGallery(fileevent) {


            const array_files=fileevent.target.files;
            const total_images=array_files.length;




            if(total_images>0){

                    const config = {
                        onUploadProgress: event => {

    
                            const uploadprogress = parseInt(Math.round((event.loaded * 100) / event.total));

                            this.uploadprogress=uploadprogress;

                            const uploaded_items=Math.round((uploadprogress*total_images)/100);
                            
                            if(event.loaded==event.total){
                                this.uploadingtext='Procesando...';
                            }else{

                                this.uploadingtext=uploaded_items.toString()+' de '+total_images+' imágenes';
                            }



                        },

                        //headers:{'Content-type':'form/data'}
                    };
                    const data = new FormData();
                    
                    data.append('development_id',this.model.id);
                    for (let i = 0; i < total_images; i++) {
                        data.append('gallery[]', array_files[i]);
                    }


                    this.uploading=true;
                    api.post(this.me('/uploadgallery'), data, config).then(response => {
                        for(const itemg in response.data){

                            console.log(itemg);
                            console.log(response.data[itemg]);
                            this.model.developmentgalleries.push(response.data[itemg]);
                        }

                    }).catch((error)=>{


                        console.log(error);


                    }).finally(()=>{

                        this.uploading=false;
                        this.uploadingtext='';

                    });

            }

        },

        afterGalleryDelete(index){
            this.model.developmentgalleries.splice(index,1);
        },



        afterExpenseSave(item){

            console.log(item);


            this.$refs.formModalExpense.hide();



            this.expensesinstance++;


        },

        updateDocument(item){

            this.document_id=item.id;
            this.$refs.modalDocument.show();

        },

        openDocumentDialog(){

            this.$refs.modalDocument.show();

        },

        onDocumentSaved(r){

            this.$refs.modalDocument.hide();

            if(this.model.documents.find(function(item){return item.id==r.data.id})){
                const index=this.model.documents.findIndex(function(it){return it.id==r.data.id});
                
                
                this.model.documents[index]=r.data;

            }else{
                this.model.documents.push(r.data);

            }

            this.document_counter++;

        }


    },



    
    mounted(){


        
            const that=this;
            

            


            if(!this.isNewRecord){
                this.created=true;
                    api.get(this.me(this.$props.id+'?expand=documents,designs,developmentAmenities,developmentgalleries,properties,properties.design,phases,processes'))
                    .then(response=>{
                        
                            const data=response.data;

                            


                            this.fillModel(this.model,data);

                            this.fillDevelopmentAmenities(response);


                            this.pic=this.thumb(response.data.featured_image);
                            this.budgets=response.data.budgets;
                            this.currencies=response.data.currencies;
                            this.show_budget=true;
                            this.$emit('onData',response.data);

                            

                    

                        }).catch(error=>{

                            console.log(error);

                        });
            
                        
            }else{

                this.show_budget=true;
                api.get('/amenities')
                    .then(response=>{
                            const data=response.data;

                            
                            this.model.catalog_amenities=data;
                        
                        }).catch(error=>{

                            console.log(error);

                        });


            }



        

        this.$on('afterSave', (response) => {
    
            
            if(!this.created){
                this.created=true;
                this.$nextTick(() => {
                    this.$set(this, 'currentTab', 1);
                });
    
            }

            this.isNewRecord=false;
            this.model.id=response.data.id;
            this.pic=that.thumb(response.data.featured_image);
            this.$refs.featured_image.value=null;
 
       });




       
       this.$root.$on('bv::dropdown::selected', bvEvent => {
        console.log('Dropdown is about to be shown', bvEvent)
        })
        



    },



}


</script>

<template>

  <section class="todo-list">
    <form @submit.prevent="addTask">
      <div class="add-items d-lg-flex">
        <input v-model="taskName" required type="text" class="form-control todo-list-input" :placeholder="placeholder">
        <submit-button :loading_flag="loading" :label="add_text"/>
        
      </div>
    </form>
    <div class="list-wrapper">
      <ul class="d-flex flex-column-reverse todo-list">
        <li v-for="(task,index) in tasks" :key="task.id">
          <div class="form-check">
            <label class="form-check-label" v-if="!task.editing">
            <input class="checkbox" v-model="task.active" type="checkbox">{{task.name}}<i class="input-helper"></i>
            </label>
            <input v-if="task.editing" type="text" v-model="task.name" class="form-control"/>
        
          </div>

          <div class="float-right task-options">
            <span v-if="!task.editing">
                <a @click="editTask(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-square-edit-outline"></i></a>
              <a @click="deleteTask(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-close-circle-outline"></i></a>
            </span>
            <span v-if="task.editing">
                <a @click="updateTask(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-check"></i></a>
                <a @click="cancelEdit(index)" class="action-link" href="javascript:void(0)"><i class="mdi mdi-cancel"></i></a>
            </span>
          </div>

        </li>
      </ul>
    </div>
  </section>

</template>

<script lang="js">

import submitButton from '@/components/ui-feature/submit-button';
//import api from '@/util/api.js';

export default {
  name: 'tasks',
  components:{
    submitButton

  },
  data () {
    return {
      loading:false,
      tasks:[],
      taskName: ''
    }
  },

  props:{
    add_text:{
      type:String,
      required:false,
      default:'Agregar'

    },
    placeholder:{
      type:String,
      required:false,
      default:'Escribe el nombre de la tarea'

    },
    value:{},

  },
  mounted () {
    
    for(let i in this.value){

        const it=this.value[i];
        this.tasks.push({name:it.name,id:it.id,active:it.active,editing:false});

    }


  },
  methods: {

    
    cancelEdit(index){
        this.tasks[index].name=this.tasks[index].aux_name;
        this.tasks[index].editing=false;

    },
    editTask (index) {

        this.tasks[index].aux_name=this.tasks[index].name;
        this.tasks[index].editing=true;
    },
    
    updateTask (index) {
        
        
        this.tasks[index].editing=false;

    },

    addTask(){


        this.tasks.push({name:this.taskName,id:0,active:1,editing:false});
        this.taskName='';
        this.$notify({message:'Debes guardar para aplicar los cambios','type':'warning'});

    },
    
    deleteTask (index) {

        if(confirm('¿Eliminar?')){
            this.tasks.splice(index,1);
            this.$notify({message:'Debes guardar para aplicar los cambios','type':'warning'});
        }

    }
  },

  watch:{
    tasks:{
        handler:function(){
        
            this.$emit('input',this.tasks);
        },
        deep:true
    }


  }
}
</script>

<style scoped lang="scss">
.todo-list {
}
.list-wrapper ul li .form-check{max-width: 100%;width:100%;}
.task-options{width:20%}

</style>

<template>
      
        <div class="wrapper">
              <h5 class="pull-left">Fases del desarrollo</h5>
              <div class="table-responsive">
  
  
                

                <table class="table">
                  <thead>
                    <tr>
                      <th><input type="checkbox"></th>
                      <th> ID </th>
                      <th> Nombre </th>
                      <th> Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
  
                    <tr v-if="listing">
                      <td colspan="10">
                        <b-skeleton-table
                          :rows="5"
                          :columns="5"
                          :table-props="{ bordered: true, striped: true }"
                          
                        ></b-skeleton-table>
                      </td>
                    </tr>
  
  

                    
                    <tr v-for="(item, index) in phases" :key="index">
                      <td><input type="checkbox"></td>
                      <td>{{item.id}}</td>
                      <td>{{ item.name }}</td>
                      <td>
                        <a class="action-link" @click="openStageDialog(item.id,index)" :title="phase_title_plural()"><i class="mdi mdi-map-legend"></i> {{ phase_title_plural() }}</a>
                        <a class="action-link" @click="editMe(item.id,index)"><i class="mdi mdi-square-edit-outline"></i></a>
                        <a class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                      </td>
                    </tr>


                    <tr v-if="phases.length==0">
                      <td colspan="10" class="tcenter"><p>No hay fases registradas</p></td>
                    </tr>

                    <tr>
                      <td colspan="10">
                        <a @click="openPhaseDialog" class="action-link" href="javascript:void(0)"><i class="mdi mdi-home-plus-outline"></i> Agregar fase</a>
                      </td>
                  </tr>
                  

                  </tbody>
                </table>
              </div>
                <b-modal ref="modalPhase" id="form-modal" size="sm" title="Editar fase" :hide-footer="true">
                    <formPhase :key="form_key" v-if="phases" :id="parseInt(phase_id)" :development_id="parseInt(development_id)" @afterSave="afterPhaseSave" ref="formPhase"/>
                </b-modal>

                <b-modal  @shown="onStageDialogReady" @hidden="onStageDialogHidden" ref="modalStage" id="modal-stage" size="lg" :title="phase_title_plural(true)+' de la fase '" :hide-footer="true">

                    
                    
                        <div class="row">

                          <div class="col-md-4 col-sm-4 col-lg-4 side-col">
                            <div class="card">
                              <div class="card-body">
                                <h4 class="card-title">{{ phase_title_plural() }}</h4>

                                <div v-if="!emptyStages" class="stages-list" :key="key_stages">
                                  <div v-for="(stage, index) in stages" :key="index" class="row-stage" :class="selectedStage(stage)">


                                    <span class="stage-options float-right">
                                      <span v-if="!stage.editable">
                                        <ui-switch @input="onSwitchChange($event,index)" :outputType="'numeric'" v-model="stage.active" v-b-tooltip.hover title="Activar/Desactivar"></ui-switch>
                                        <a href="javascript:void(0)" @click="editStage(index)" class="action-link"><i class="mdi mdi-square-edit-outline"></i></a>
                                        <a href="javascript:void(0)" @click="deleteStage(index)" class="action-link"><i class="mdi mdi-delete-outline"></i></a>
                                      </span>
                                      <span v-if="stage.editable">
                                        <b-spinner small v-if="saving_stage" label="Creando..."></b-spinner>
                                        <a v-if="!saving_stage" href="javascript:void(0)" @click="saveStage(index)" class="action-link"><i class="mdi mdi-check"></i></a>
                                        <a href="javascript:void(0)" @click="cancelStage(index)" class="action-link"><i class="mdi mdi-cancel"></i></a>
                                      </span>
                                    </span>
                                    <input class="form-control inline" type="text" v-if="stage.editable" v-model="stage.name" :placeholder="'Escribe el nombre'" />
                                    <a href="javascript:void(0)" v-if="!stage.editable" @click="onSelectStage(stage)" class="stage-name"><i class="mdi mdi-map-outline"></i>{{ stage.name }}</a>
                                  </div>


                                </div>
                                <div v-else>
                                  <p>No has registrado {{  }}</p>
                                </div>

                                <br>
                                <a href="javascript:void(0)" @click="addStage" class="action-link"><i class="mdi mdi-map-check-outline"></i> Agregar {{ phase_title_singular() }}</a>
                                
                              </div>
                              
                            </div>

                            <br>
                            <div class="card">
                                <div class="card-body">


                                <h4 class="card-title">Inventario</h4>


                                <div class="search-props">
                                    
                                    <div class="form-group">
                                      <a href="javascript:void(0)" v-if="searchKey!=''" class="action-link close-prop-search" @click="searchKey=''"><i class="mdi mdi-close-circle-outline"></i></a>
                                      <input type="text" class="form-control form-control-lg" v-model="searchKey" placeholder="Escribe para buscar"/>
                                    </div>

                                </div>
                                
                                <div class="properties-list" v-if="hasProperties">
                                    <div v-for="(prop,index) in dynamicProperties" :key="index">
                                      
                                        <div class="row-prop">
                                            <a class="prop-item" :class="prop.status" @click="openProp(prop)" href="javascript:void(0)"><i class="mdi mdi-map-marker"></i> {{ prop.code }}</a>
                                        </div>
                                    
                                    </div>
                                </div>

                                <div v-else>
                                  <p>Selecciona {{ phase_title_singular() }}</p>

                                </div>

                                </div>
                            </div>


                          </div>
                          <div class="col-md-8 col-sm-8 col-lg-8 center-map">


                            <div class="map-area">
                            
                                    <div class="empty-map" v-if="!hasMap && !uploading">

                                      <div class="area-upload" v-if="hasStage && !emptyStages">
                                        <i class="mdi mdi-map-outline"></i>
                                        Sube la imagen de mapa
                                        <input type="file" ref="mapfile" @change="uploadMap" accept=".jpg,.jpeg,.png,.x-png,.gif"/>
                                      </div>

                                      <div class="area-upload" v-if="!hasStage && !emptyStages">
                                        <i class="mdi mdi-map-outline"></i>
                                        Elige {{ phase_title_singular() }}                                        
                                      </div>



                                      <div class="area-create" v-if="emptyStages">
                                        Debes crear {{ phase_prefix_singular() }} {{ phase_title_singular() }}
                                      </div>

                                    </div>

                                    <div class="uploading-status" v-if="uploading">
                                      <progress-bar :text-position="'top'" :text="'Cargando'" :size="'large'" :val="uploadprogress" :max="100" :bar-color="'linear-gradient(to right, #d42f65, #a7357e)'"/>
                                    </div>
                                    
                                    <l-map

                                        v-if="hasMap && !uploading"
                                        ref="lmap"
                                        :key="key_map"
                                        :min-zoom="map.minZoom"
                                        :crs="map.crs"
                                        style="height: 550px; width:100%"
                                        :center="map.center"
                                        :bounds="map.bounds"
                                        :maxBounds="map.maxBounds"
                                        :maxBoundsViscosity="1000"
                                        :easeLinearity="0"
                                        :inertia="false"
                                        @click="onMapClicked"
                                      >


                                      <l-control position="bottomright">
                                        <span class="map-tip-c"><i class="mdi mdi-map-marker-plus-outline"></i> Haz clic en algun punto en el mapa para agregar una unidad al inventario</span>
                                      </l-control>
                                      <l-control>

                                        <span href="javascript:void(0)" class="lf-custom-control"><i class="mdi mdi-map-outline"></i> Cambiar mapa<input type="file" ref="mapfilec" @change="uploadMap" accept=".jpg,.jpeg,.png,.x-png,.gif"/></span>
                                      </l-control>
                                        <l-image-overlay
                                          :url="map.url"
                                          :bounds="map.bounds"
                                          
                                        />
                                        <l-marker
                                          v-for="(marker,idx) in map.markers"
                                          :key="idx"
                                          :lat-lng="{lat:marker.position_x,lng:marker.position_y}"
                                          :icon="marker.icon"
                                          :draggable="true"
                                          ref="markers"
                                          @dragend="onMarkerUpdate($event,idx)"
                                          @click="onMarkerClick(idx)"
                                          
                                        >
                                          <l-popup  @remove="onPopupClose" ref="popups" :options="{minWidth:250,offset:map.offset,keepInView:true}">

                                              
                                                
                                              <form @submit.prevent="saveProperty">

                                                <div class="form-group">
                                                  <label>Clave</label>
                                                  <input type="text" required class="form-control form-control-lg" v-model="marker.code"/>
                                                </div>
                                                <div class="row">

                                                  <div class="col-md-6 col-sm-6 col-lg-6 pad-right-sm">
                                                    <div class="form-group">
                                                      <label>Modelo</label>
                                                      <select required  class="form-control" v-model="marker.design_id">
                                                        <option v-for="(design,index) in designs" :key="index" :value="design.id">{{ design.name }}</option>
                                                      </select>
                                                    </div>                                                    
                                                  </div>
                                                  <div class="col-md-6 col-sm-6 col-lg-6 pad-left-sm">

                                                    <div class="form-group">
                                                      <label>Estatus</label>
                                                      <select required class="form-control" v-model="marker.status">
                                                        <option value="available">Disponible</option>
                                                        <option value="deposit">Apartado</option>
                                                        <option value="sold">Vendido</option>
                                                      </select>
                                                </div>                                                    
                                                  </div>
                                                </div>




                                                
                                                
                                                
                                                <b-input-group>
                                                  <template #prepend>
                                                      <b-input-group-text>
                                                        <button href="javascript:void(0)" v-if="!loading" class="action-link clean-button"><i class="mdi mdi-check"></i></button>
                                                        <b-spinner class="float-right" small v-if="loading" label="Cargando..."></b-spinner>
                                                      </b-input-group-text>
                                                  </template>
                                                  
                                                  <template #append>
                                                  <b-input-group-text>
                                                    <a href="javascript:void(0)" @click="deleteMarker(idx)" class="action-link"><i class="mdi mdi-delete"></i></a>
                                                  </b-input-group-text>
                                                </template>
                                                </b-input-group>


                                                </form>
                                              
                                              

                                          </l-popup>
                                        </l-marker>

                                        
                                        
                                        <l-polyline :lat-lngs="map.travel" />
                                      </l-map>


                                  </div><!----.map-area-->


                                  
                                




                            </div>



                          

                        </div>
                    
                    
                    
                    

                </b-modal>



            
            </div>


  </template>
  
  <script>
  
  import api from '@/util/api.js';
  import formPhase from '@/pages/phases/_form';

  import L from 'leaflet';
  import { CRS } from "leaflet";
  import { latLngBounds } from "leaflet";

  import { LMap, LImageOverlay, LMarker, LPopup, LPolyline,LControl} from "vue2-leaflet";
  import ProgressBar from 'vue-simple-progress';
  import uiSwitch from '@/components/ui-feature/switch';

//  import submitButton from '@/components/ui-feature/submit-button';



  
  
  export default {
    routeName:'phases',
    label:'Fases',
    name: 'embedListPhases',
    components: {
      formPhase,
      uiSwitch,
      ProgressBar,

      LMap,
        LImageOverlay,
        LMarker,
        LPopup,
        LPolyline,
        LControl
    },

    props:{
      data:{
        type:Array,
        required:false
      },
      development_id:{
        required:false
  
      },
      designs:{
        
      },
      dev_type:{

      }

    },
    data() {
      return {

        saving_stage:false,
        uploading:false,
        uploadprogress:0,
        map_padding:350,
        marker_clicked:null,
        searchKey:'',
        adding:false,
        loading:false,
        currentStage:{},
        key_stages:0,
        key_map:0,
        ready:false,
        form_key:0,
        phase_id:'',
        model:{id:''
      
              },

        property:{},
        stages:[],
        properties:[],
        
        phases:[],
        listing:false,
        map:{
                url: '',
                offset:L.point(0, -30),
                //autoPanPadding:L.point(200,200),
                bounds:[],
                maxBounds:[],
                center: [],
                minZoom: -1,
                crs: CRS.Simple,
                markers: [],

            }

      };
    },

    computed:{





      phase_prefix_singular(){

        return function(u){
          let t=this.dev_type=='condominio_horizontal'?'una':'un';

          if(u){

            t=this.textUpper(t);

          }

          return t;

        }

      },
      phase_title_plural(){


        return function(u){
          let t=this.dev_type=='condominio_horizontal'?'etapas':'pisos';

          if(u){

            t=this.textUpper(t);

          }

          return t;
        }
        

        

      },

      phase_title_singular(){
        return function(u){
          let t=this.dev_type=='condominio_horizontal'?'etapa':'piso';

          if(u){

            t=this.textUpper(t);

          }

          return t;
        }

      },

      dynamicProperties(){


        const key=this.searchKey.toLowerCase();
        return this.properties.filter(property =>
        property.code.toLowerCase().includes(key)
        );


      },

      hasProperties(){

        return this.properties.length>0?true:false;

      },
      hasMap(){

        return this.map.url?true:false;

      },

      hasStage(){

        return this.currentStage.id!=undefined && this.currentStage.id!=0?true:false;

      },

      emptyStages(){

        return this.stages.length==0?true:false;

      },

      numrows(){
        
        return this.phases?this.phases.length:0;
      },

      isReady(){
        return this.ready && this.map.url!='';
      }

    },
  
  
    methods: {


      textUpper(t){

      return t.charAt(0).toUpperCase() + t.slice(1);

      },
      uploadMap(event){

          const data=new FormData();

          data.append('map',event.target.files[0]);
          data.append('phase_id',this.currentStage.id);

          this.uploading=true;

          api.post(this.me('/uploadmap'),data,

          {
            onUploadProgress:event=>{

              this.uploadprogress=parseInt(Math.round((event.loaded * 100) / event.total));

            
            }
          }
          
          
          ).then(response=>{

            
              this.configMap(response.data);

          }).finally(()=>{
            this.uploading=false;

          });
        
        

      },

      onMarkerUpdate(event,index){


        
        const latlng=event.target._latlng;


        const m=this.map.markers[index];
        m.position_x=latlng.lat;
        m.position_y=latlng.lng;
        if(m.id==0)return;

        api.patch('/properties/'+m.id,{
          position_x:latlng.lat,
          position_y:latlng.lng
        }).then(()=>{
          
          this.$notify({message:'Ubicación actualizada',type:'success'});
        }).catch(()=>{


        }).finally(()=>{

        });
        


      },

      onMarkerClick(index){


        this.marker_clicked=index;
        this.adding=true;
        

      },

      openPhaseDialog(){

        this.form_key++;
        this.phase_id=0;
        this.$refs.modalPhase.show();


      },

      afterPhaseSave(){

        this.$refs.modalPhase.hide();

        this.loadPhases();

      },

      getStatus(status){

        return status.charAt(0).toUpperCase() + status.slice(1);


      },
      loadPhases() {

  
        this.listing=true;
        
        api.get(this.me('?filter[development_id]='+this.development_id)+'&filter[parent_id]=').then(response => {
          this.phases = response.data;
  
          
        }).catch(error => {
          console.log(error);
        }).finally(()=>{
  
          this.listing=false;
  
        });
      },


      editMe(id){

        
        
        this.phase_id=id;
        this.$refs.modalPhase.show();


      },
  
      openStageDialog(id){

        this.phase_id=id;
        this.$refs.modalStage.show();
        
      },
      
      
      onStageDialogReady(){
        

        this.ready=true;
        this.loadStages(this.phase_id);


      },

      onStageDialogHidden(){


        this.resetMap();
        this.ready=false;

      },

  
      deleteMe(id,index){
  
  
  
  
  
        if(confirm('¿Desea eliminar el elemento?')) {
  
                api.delete(this.me(id)).then(()=>{
  
                  this.phases.splice(index,1);
                  this.loadPhases();
                  
                
                }).catch(error=>{
  
                  console.log(error);
                  
                });
  
          }
  
        },

    addStage(){

      const item={name:'',active:false,editable:true,id:0};
      this.stages.push(item);
      this.onSelectStage(item);

    },

    editStage(index){

      this.stages[index].editable=true;
      this.key_stages++;

    },


    deleteStage(index){


      const stage=this.stages[index];
      if(confirm('¿Eliminar? se eliminarán también todas las propiedades')){

        api.get('/properties/deleteitems?phase_id='+stage.id).then(()=>{

            api.delete(this.me('/'+this.stages[index].id)).then(()=>{

            this.$notify({message:'Elemento eliminado',type:'success'});
            this.stages.splice(index,1);

            this.resetInventory();


            });

        }).catch(()=>{

            this.$notify({message:'No se pudo eliminar el elemento',type:'error'});

        });  
      


      }

    },

    cancelStage(index){

      if(this.stages[index].id==0){
        this.stages.splice(index,1);
      }else{
        this.stages[index].editable=false;
      }

      this.key_stages++;
    },

    saveStage(index){

      const data=this.stages[index];
      data.active=data.active?1:0;
      data.parent_id=this.phase_id;
      data.development_id=this.development_id;


      this.saving_stage=true;

      api.request({
        url:this.me('/'+data.id!=0?data.id:''),
        data,
        /*headers:{'Content-type':'form/data'},*/
        method:data.id==0?'POST':'PATCH',
        }).then(response=>{

        this.stages[index]=response.data;
        //this.stages[index].editable=false;
        this.currentStage=this.stages[index];
        

        this.key_stages++;
      }).finally(()=>{
        this.saving_stage=false;

      });

    },


    selectedStage(item){

      return this.currentStage.id==item.id?'active':'';


    },

    onSelectStage(item){
      this.currentStage=item; 
      this.resetMap();
      this.properties=[];
      this.loadStage(item.id);


    },

    loadStage(id){


      if(id==0)return;
      

          api.get('/phases/'+id+'?expand=properties').then(r=>{

            this.configMap(r.data);
            this.properties=r.data.properties;
            
            
            }).catch(e=>{

            console.log(e);

            }).finally(()=>{});
      

    },

    loadStages(parent_id){


      api.get(this.me('?filter[development_id]='+this.development_id)+'&filter[parent_id]='+parent_id).then(response => {
          this.stages = response.data;

          this.loadFirstStage();
          
        }).catch(error => {
          console.log(error);
        }).finally(()=>{
  
          this.listing=false;
  
        });

    },

    loadFirstStage(){

      if(this.stages.length>0){
          this.currentStage=this.stages[0];
          this.loadStage(this.stages[0].id);

      }


    },

    onMapClicked(event){
      
      if(!this.adding){
        this.map.markers.push({id:0,code: "", position_x: event.latlng.lat, position_y: event.latlng.lng,draggable:true,icon:this.makeMarkerIcon('available'),showing:true});
        
        const that=this;
        setTimeout(function(){
          const idx=that.$refs.markers.length-1;
          that.$refs.markers[idx].mapObject.openPopup(event.latlng);
          that.marker_clicked=idx;
          that.adding=true;
          
        },50);
        

        
      }

      

    },


    resetInventory(){
      this.properties=[];
      this.map.markers=[];
      this.map.url='';
      this.currentStage={};
    },  

    resetMap(){


      this.map.url='';
      this.map.bounds={};
      this.map.maxBounds={};
      this.map.markers=[];

    },

    configMap(config){
      

      if(config.hasOwnProperty('map')){
        
        this.map.url=this.$pic(config.map);
      }
      
      if(config.hasOwnProperty('map_width') && config.hasOwnProperty('map_height')){

        const map_width=parseFloat(config.map_width);
        const map_height=parseFloat(config.map_height);

        this.map.bounds=latLngBounds([[0, 0], [map_height,map_width]]);
        this.map.maxBounds=latLngBounds([[-this.map_padding, -this.map_padding], [map_height+this.map_padding,map_width+this.map_padding]]);
        this.map.center=[map_width/2,map_height/2];

        
        
      }


      
      if(config.hasOwnProperty('properties')){
        

              for(let i in config.properties){

                
                const p=config.properties[i];

                this.map.markers.push({
                  id:p.id,
                  code:p.code,
                  icon:this.makeMarkerIcon(p.status),
                  position_x:p.position_x,
                  position_y:p.position_y,
                  active:p.active,
                  design_id:p.design_id,
                  status:p.status,
                  draggable:true
                });
          

        }


      }




      this.key_map++;




    },


    makeMarkerIcon(status){

      let icon='';
            switch(status){
                  case 'available':icon='marker-available.png';break;
                  case 'deposit':icon='marker-hold.png';break;
                  case 'sold':icon='marker-sold.png';break;

                }

           return L.icon({
                  iconUrl: '/images/'+icon,
                  iconSize: [25, 35],
                  iconAnchor: [16, 37]
                })
    },

    getCurrentMarker(){
      return this.map.markers[this.marker_clicked];
    
    },


    saveProperty(){

        const m=this.getCurrentMarker();

        
        
        const updating=m.id!=0 && m.id!=undefined?true:false;

        const data=this.map.markers[this.marker_clicked];

        data.development_id=this.$props.development_id;
        data.phase_id=this.currentStage.id;

        this.loading=true;

        api.request({
              url:'/properties'+(updating?'/'+m.id:''),
              data,
              method:updating?'PATCH':'POST',
            
            }).then(r=>{
              this.$notify({message:'Elemento agregado',type:'success'});


              this.$refs.markers[this.marker_clicked].mapObject.closePopup();
              if(updating){
                
                this.updateProperty(r.data);
              }else{
                this.pushProperty(r.data);
              }

        }).catch(()=>{


          this.$notify({message:'Hubo un error',type:'error'});


        }).finally(()=>{

          this.loading=false;
        });


    },

    pushProperty(item){

      this.map.markers[this.marker_clicked].id=item.id;      
      this.map.markers[this.marker_clicked].icon=this.makeMarkerIcon(item.status);
      this.properties.push(item);


    },

    updateProperty(item){

      this.map.markers[this.marker_clicked].id=item.id;
      this.properties[this.marker_clicked].code=item.code;
      this.properties[this.marker_clicked].status=item.status;
      this.properties[this.marker_clicked].design_id=item.design_id;
      //this.map.markers[index].icon=this.makeMarkerIcon(status.target.value);
      
      this.map.markers[this.marker_clicked].icon=this.makeMarkerIcon(item.status);
      

    },

    onPopupClose(){


      let that=this;
      this.resetCurrentMarker();
      setTimeout(function(){
        that.adding=false;
      },50);

    },

    resetCurrentMarker(){

      if(this.map.markers[this.marker_clicked].id!=0 && !this.loading){
        this.copyMarkerAttributesFromProperty();
      }

    },

    copyMarkerAttributesFromProperty(){
        this.map.markers[this.marker_clicked].code=this.properties[this.marker_clicked].code;
        this.map.markers[this.marker_clicked].status=this.properties[this.marker_clicked].status;
        this.map.markers[this.marker_clicked].design_id=this.properties[this.marker_clicked].design_id;


    },

    cleanAttributes(){

    },


    deleteMarker(index){

        const m=this.map.markers[index];
        
        
        this.adding=false;
        if(m.id==0){

          this.map.markers.splice(index,1);
          
          
        }else if(confirm('¿Eliminar?')){
          
          api.delete('/properties/'+m.id).then(()=>{
            this.map.markers.splice(index,1);
          }).catch(()=>{

            this.$notify({message:'No se pudo eliminar',type:'error'});

          })

        }




    },


    openProp(item){



      this.adding=true;
      
      const index=this.map.markers.findIndex((el)=>{
        return el.id==item.id;
      })
      this.marker_clicked=index;
      const latlng={lat:this.map.markers[index].position_x,lng:this.map.markers[index].position_y};
      this.map.center=latlng;
      this.$refs.markers[index].mapObject.openPopup(latlng);


    },

    onSwitchChange(event,index){
      

      const st=this.stages[index];
      const data=new FormData;
      data.append('active',event);
      if(st.id!=0){
        api.patch(this.me('/'+st.id),data).then(()=>{

          this.$notify({message:'Elemento '+(event==1?'activado':'desactivado'),type:'success'});

        });
      }


    }


            
  
        
    },
  
    mounted(){
  
      if(this.data){      
        this.phases=this.data;
      
      }else{
//        this.loadPhases();
      }
  },



  

}
  
  
  </script>


<style lang="scss" scoped>

.center-map{padding-left:0}
#modal-state .side-col .card-body{padding:20px}

.row-stage{position:relative;border-radius: 5px;}

.stage-name{padding:12px;display:block;color:#000;text-decoration: none;}
.row-stage:hover,.row-stage.active{background-color:#f2edf3 ;}


.stage-options{position:absolute;right:0;top:11px;right:10px}

.uploading-status,.empty-map{height: 550px;background: #fff;border-radius: 5px;display: flex;justify-content: center;align-items: center;}
.empty-map input{opacity: 0;width:100%;height:100%;position:absolute;z-index:10;top:0;left:0}

.map-area{margin-bottom:20px}

.lf-custom-control{background:#fff;color:#000;padding:10px;position:relative;display:inline-block;cursor:pointer}
.lf-custom-control input[type=file]{opacity:0;width:100%;height:100%;position:absolute;top:0;left:0;}

.prop-item{color:#000}

.search-props{position:relative}

.close-prop-search{
    position: absolute;
    right: 2px;
    top: 12px;}

.row-prop{padding:5px}

.prop-item.available i{color:rgba(6, 185, 157, 1)}
.prop-item.deposit i{color:rgb(255, 229, 81)}
.prop-item.sold i{color:rgb(203, 80, 111)}

.properties-list{max-height:100px;overflow: auto;}


.map-tip-c{color:#fff}

</style>
  
  
  
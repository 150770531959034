<template>

    <div class="thumb-item">

        <img v-bind:src="imageSrc" v-if="$props.image" class="responsive"/>
        <div class="thumb-footer">
            <span class="thumb-d-name" v-text="$props.name"></span>
            <div class="float-right thumb-actions">
                <a @click="editMe" class="action-link"><i class="mdi mdi-square-edit-outline"></i></a>
                <a href="javascript:void(0)" class="action-link" @click="deleteMe"><i class="mdi mdi-delete-outline"></i></a>
            </div>
        </div>

    </div>


</template>

<script>


    import api from '@/util/api.js';

    export default{
        routeName:'designs',
        name:"designThumb",
        props:{
            id:{
                type:Number,
                required:true,
            },
            index:{
                type:Number,
                required:true,
            },
            image:{
                type:String,
                required:true,
            },
            name:{
                type:String,
                required:true,
            },

        },

        computed:{

            imageSrc(){

                return this.thumb(this.$props.image);

            }

        },

        data(){

            return {

             

            }

        },  

        mounted(){

                        

        },
        methods:{


            editMe(){
                this.$emit('edit',this.id);

            },  



            destroyMe(){

                this.$destroy();

            },
            deleteMe(){

                if(confirm('¿Eliminar?')){

                    api.delete(this.me(this.$props.id)).then(()=>{
                        
                        this.$emit('afterDelete',this.$props.index);
                        
                    });
                    
                }
            }

        }

    }

</script>
<template>
      
        <div class="wrapper">

              <div class="table-responsive">
  
  
                

                <table class="table">
                  <thead>
                    <tr>
                      <th><input type="checkbox"></th>
                      <th> ID </th>
                      <th> Nombre </th>
                      <th> Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
  
                    <tr v-if="listing">
                      <td colspan="10">
                        <b-skeleton-table
                          :rows="numrows"
                          :columns="5"
                          :table-props="{ bordered: true, striped: true }"
                          
                        ></b-skeleton-table>
                      </td>
                    </tr>
  
  

                    
                    <tr v-for="(item, index) in items" :key="index">
                      <td><input type="checkbox"></td>
                      <td>{{item.id}}</td>
                      <td>{{ item.name }}</td>
                      <td>
                        <a class="action-link" @click="editMe(item.id,index)"><i class="mdi mdi-square-edit-outline"></i></a>
                        <a class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                      </td>
                    </tr>


                    <tr v-if="items.length==0">
                      <td colspan="10" class="tcenter"><p>No hay registros</p></td>
                    </tr>

                    <tr>
                      <td colspan="10">
                        <a @click="openProcessDialog" class="action-link" href="javascript:void(0)"><i class="mdi mdi-home-plus-outline"></i> Agregar paso</a>
                      </td>
                  </tr>
                  

                  </tbody>
                </table>
              </div>
              <b-modal ref="modalProcess" id="form-modal" size="sm" title="Agregar paso" :hide-footer="true">
              
                <formProcess :key="form_key" v-if="items" :id="parseInt(property_id)" :development_id="parseInt(development_id)" @afterSave="afterProcessSave" ref="formProperty"/>
              
                
              
              </b-modal>
            
            </div>


  </template>
  
  <script>
  
  import api from '@/util/api.js';
  import formProcess from '@/pages/processes/_form';
  
  
  
  export default {
    routeName:'processes',
    label:'Pasos',
    name: 'embedListProcesses',
    components: {
      formProcess
    },

    props:{
      data:{
        type:Array,
        required:false
      },

      development_id:{

        required:false

      },

    },
    data() {
      return {

        form_key:0,
        property_id:'',
        items:[],
        listing:false,
        designList:[],
        design_id:0,
        developments:[],
        designs:[],
       
      };
    },

    computed:{

      numrows(){
        return this.items.length;
      }

    },
  
  
    methods: {

      openProcessDialog(){

        this.form_key++;
        this.property_id=0;
        this.$refs.modalProcess.show();

      },

      afterProcessSave(data){

          console.log(data);
          this.$refs.modalProcess.hide();
          this.getItems();

      },

      getStatus(status){

        return status.charAt(0).toUpperCase() + status.slice(1);


      },
      getItems() {

  
        this.listing=true;
        
        api.get(this.me('?expand=development,design&filter[development_id]='+this.development_id)).then(response => {
          this.items = response.data;
  
          console.log(this.items);
  
          
        }).catch(error => {
          console.log(error);
        }).finally(()=>{
  
          this.listing=false;
  
        });
      },


      editMe(id){

        
        
        this.property_id=id;
        this.$refs.modalProcess.show();


      },
  
  
      deleteMe(id,index){
  
  
  
  
  
        if(confirm('¿Desea eliminar el elemento?')) {
  
                api.delete(this.me(id)).then(()=>{
  
                  this.items.splice(index,1);
                  this.getItems();
                  
                
                }).catch(error=>{
  
                  console.log(error);
                  
                });
  
          }
  
        }
            
  
        
    },
  
    mounted(){
  
        this.items=this.data;
        
      }
  
  }
  
  
  
  </script>
  
  
  
<template>
      
        <div class="wrapper">

              <div class="table-responsive">
  
  
                

                <table class="table">
                  <thead>
                    <tr>
                      <th><input type="checkbox"></th>
                      <th> ID </th>
                      <th> SKU/Clave </th>
                      <th> Diseño </th>
                      <th> Estatus </th>
                      <th> Acciones </th>
                    </tr>
                  </thead>
                  <tbody>
  
                    <tr v-if="listing">
                      <td colspan="10">
                        <b-skeleton-table
                          :rows="numrows"
                          :columns="5"
                          :table-props="{ bordered: true, striped: true }"
                          
                        ></b-skeleton-table>
                      </td>
                    </tr>
  
  

                    
                    <tr v-for="(item, index) in items" :key="index">
                      <td><input type="checkbox"></td>
                      <td>{{item.id}}</td>
                      <td>{{ item.code }}</td>
                      <td>{{ item.design.name }}</td>
                      <td>{{ getStatus(item.status) }}</td>
                      <td>
                        <a class="action-link" @click="editMe(item.id,index)"><i class="mdi mdi-square-edit-outline"></i></a>
                        <a class="action-link" @click="deleteMe(item.id,index)"><i class="mdi mdi-delete-outline"></i></a>
                      </td>
                    </tr>


                    <tr v-if="items.length==0">
                      <td colspan="10" class="tcenter"><p>No hay registros</p></td>
                    </tr>

                    <tr>
                      <td colspan="10">
                        <a @click="openPropertyDialog" href="javascript:void(0)"><i class="mdi mdi-home-plus-outline"></i> Agrega elemento</a>
                      </td>
                  </tr>
                  

                  </tbody>
                </table>
              </div>
              <b-modal ref="modalProperty" id="form-modal" size="sm" title="Editar unidad" :hide-footer="true">
                    <formProperty :key="form_key" v-if="items" :id="parseInt(property_id)" :default_developments="developments" :default_designs="default_designs" :development_id="parseInt(development_id)" @afterSave="afterPropertySave" ref="formProperty"/>
                </b-modal>
            
            </div>


  </template>
  
  <script>
  
  import api from '@/util/api.js';
  import formProperty from '@/pages/properties/_form';
  
  
  
  export default {
    routeName:'properties',
    label:'Unidades',
    name: 'embedListProperties',
    components: {
      formProperty
    },

    props:{
      data:{
        type:Array,
        required:false
      },

      default_developments:{

        type:Array,
        required:false

      },
      default_development_id:{
        type:Number,
        required:false,
      },
      default_designs:{
        type:Array,
        required:false,
      },
      default_design_id:{
        type:Number,
        required:false
      }

    },
    data() {
      return {

        form_key:0,
        property_id:'',
        items:[],
        listing:false,
        designList:[],
        design_id:0,
        development_id:0,
        developments:[],
        designs:[],
       
      };
    },

    computed:{

      numrows(){
        return this.items.length;
      }

    },
  
  
    methods: {

      openPropertyDialog(){

        this.form_key++;
        this.property_id=0;
        this.$refs.modalProperty.show();

      },

      afterPropertySave(data){

        this.$refs.modalProperty.hide();

        console.log(data);
        this.getItems();

      },

      getStatus(status){

        return status.charAt(0).toUpperCase() + status.slice(1);


      },
      getItems() {

  
        this.listing=true;
        
        api.get(this.me('?expand=development,design&filter[development_id]='+this.development_id)).then(response => {
          this.items = response.data;
  
          console.log(this.items);
  
          
        }).catch(error => {
          console.log(error);
        }).finally(()=>{
  
          this.listing=false;
  
        });
      },


      editMe(id){

        
        
        this.property_id=id;
        this.$refs.modalProperty.show();


      },
  
  
      deleteMe(id,index){
  
  
  
  
  
        if(confirm('¿Desea eliminar el elemento?')) {
  
                api.delete(this.me(id)).then(()=>{
  
                  this.items.splice(index,1);
                  this.getItems();
                  
                
                }).catch(error=>{
  
                  console.log(error);
                  
                });
  
          }
  
        }
            
  
        
    },
  
    mounted(){
  


      if(this.default_development_id){
        this.development_id=this.default_development_id
      }
      if(this.default_design_id){
        this.design_id=this.default_design_id
      }



      if(this.default_designs){

        this.designs=this.default_designs;

        
      }
      if(this.default_developments){

        this.developments=this.default_developments;

        
      }


      if(this.data){
        this.items=this.data;
        
      
      }else{
        this.getItems();
      }
      
    }
  
  }
  
  
  
  </script>
  
  
  
<template>

            <form @submit.prevent="save">
                <div class="form-group">
                    <label for="name">Nombre del paso</label>
                    <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Escribe el nombre del paso">
                </div>

                <div class="form-group">
                    

                    <div class="card">
                        <div class="card-body">
                            <label>Define los requerimientos para este paso</label>
                            <br>
                            <br>
                            <tasks  v-if="isModelReady" v-model="model.requirements" :placeholder="'Escribe el requisito'"/>
                        </div>
                    </div>
                </div>


                <div class="form-group">
                    <submitbutton :label="'Guardar'" :loading_flag="loading"/>
                </div>
            </form>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';
import tasks from '@/components/Tasks'

export default {
    routeName:'processes',
    name: 'formProcess',
    components:{
        submitbutton,
        tasks
    },
    props:{

        id:{
            type:Number,
            required:false,
        },
        development_id:{


        }

    },
    data(){

        return {
            
            model:{
                id:'',
                name:'',
                requirements:[],
                development_id:0
            },
            
            loading:false,

        }

    },
    computed:{
        isModelReady(){
            return this.isNewRecord || (this.model.id!=0 && this.model.id!=undefined);
        }

        

    },

    methods:{


        selectFile(event) {
         this.model.icon = event.target.files[0];
        },

        save(){

            
            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me('/savewithchildrens'),
                data,
                headers:{'Content-type':'form/data'},
                method:'POST',
                }).then(response=>{

                    this.$notify({message:"Cambios guardados",type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:error.data.message,type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    
    
    mounted(){

        
        this.model.development_id=this.development_id;

        if(!this.isNewRecord){
                api.get(this.me(this.$props.id+'?expand=requirements'))
                .then(response=>{
                    
                    
                        const data=response.data;

                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }


                        
                        


                }).catch(error=>{
                    console.log(error);
                });

        }



    }


}

</script>